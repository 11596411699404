import api from "./api";
import TokenService from "./token.service";

const login = (param) => {
  return api
    .post("/student/verify_auth_id?cog_id="+param)
    .then((response) => {
      if (response.data.Students) {
        TokenService.setUser(response.data.Students[0]);
      }
      return true;
    });
};

const imageUpload = (param, imageObject, from = null) => {
  return new Promise(function (resolve, reject) {
    api.post('/student/uploadUV?'+param, { data: imageObject },).then((response) => {
      let objParam = JSON.parse('{"' + param.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
      if(objParam.from === 'kycVisualAuth') {
        TokenService.setVisualAuthStatus(true);
        resolve(response.data.Students);
      } else if(objParam.from === 'kycStudentAuth' && objParam.fileName === 'StudentID2.mp4') {
        TokenService.setStudentAuthStatus(true);
        resolve(response.data.Students);
      } else if(objParam.from === 'kycCollegeDocAuth' && objParam.fileName === 'Document_3.jpg') {
        TokenService.setCollegeDocAuthStatus(true);
        resolve(response.data.Students);
      } else {
        resolve(response.data.Students);
      }
    }).catch(function (error) {
      reject(error);
    });
  })
};
//const imageUpload = (param, imageObject) => {
//  return new Promise(function (resolve, reject) {
//  })
//  return api
//    .post('/student/uploadUV?'+param, { data: imageObject },)
//    .then((response) => {
//      return response.data.Students;
//    }).catch(function (error) {
//      return error;
//    });
//};

//const panUpdate = (param) => {
//  return api
//    .post('/aadhaar/createAadhaarIdentificationReport', { 'pan_s3_url_front': param },)
//    .then((response) => {
//      return response;
//    }).catch(function (error) {
//      return error;
//    });
//};
const panUpdate = (param) => {
  return new Promise(function (resolve, reject) {
    api.post('/aadhaar/createAadhaarIdentificationReport', { 'pan_s3_url_front': param }).then((response) => {
      resolve(response);
      TokenService.setPanStatus(true);
    }).catch(function (error) {
      reject(error);
    });
  })
};

const logout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
  imageUpload,
  panUpdate,
};

export default AuthService;
