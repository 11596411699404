import React from 'react';
import loader from './../../images/tick-icon.svg';
import './Tick.css'

//function Card(props){
export default function Tick() {
//const Loader = (props) => {
  //const classes = 'card ' + props.className;
  return (
    <div className="tick">
      <img
        src={loader}
        alt="Loading..."
        className="img-fluid"
      />
    </div>
  );
}
//export default Loader;