import React from 'react';
import { NavLink } from "react-router-dom";
//import {isMobile} from 'react-device-detect';

import './Home.css';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Card from '../components/UI/Card';
import Cancel from '../components/UI/Cancel';
import Tick from '../components/UI/Tick';

import TokenService from "../services/token.service";

import useWindowSize from '../components/useWindowSize';
export default function Home() {

//  const menuLists = [
//    {
//      href: 'pan',
//      status: TokenService.getPanStatus(),
//      title: 'PAN Authentication',
//      description: 'Upload your PAN Card',
//    },
//    {
//      href: 'visualauth',
//      status: TokenService.getVisualAuthStatus(),
//      title: 'Visual Authentication',
//      description: 'Verify using video OTP',
//    },
//    {
//      href: 'studentauth',
//      status: TokenService.getStudentAuthStatus(),
//      title: 'Student Authentication',
//      description: 'Upload your student ID card',
//    },
//    {
//      href: 'collegedocauth',
//      status: TokenService.getCollegeDocAuthStatus(),
//      title: 'College Document Authentication',
//      description: 'Upload your college issued documents',
//    },
//  ]

//  const menuLists2 = [
//    {
//      href: 'pan-authentication',
//      status: TokenService.getPanStatus(),
//      title: 'PAN Authentication 2',
//      description: 'Upload your PAN Card',
//    },
//    {
//      href: 'visual-authentication',
//      status: TokenService.getVisualAuthStatus(),
//      title: 'Visual Authentication 2',
//      description: 'Verify using video OTP',
//    },
//    {
//      href: 'student-authentication',
//      status: TokenService.getStudentAuthStatus(),
//      title: 'Student Authentication 2',
//      description: 'Upload your student ID card',
//    },
//    {
//      href: 'college-document-authentication',
//      status: TokenService.getCollegeDocAuthStatus(),
//      title: 'College Document Authentication 2',
//      description: 'Upload your college issued documents',
//    },
//    {
//      href: 'student-authentication-new',
//      status: TokenService.getStudentAuthStatus(),
//      title: 'Student Authentication new',
//      description: 'Upload your student ID card',
//    },
//    {
//      href: 'student-authentication-new1',
//      status: TokenService.getStudentAuthStatus(),
//      title: 'Student Authentication new1',
//      description: 'Upload your student ID card',
//    },
//  ];
  const menuLists = [
    {
      href: 'pan',
      status: TokenService.getPanStatus(),
      title: 'PAN Authentication',
      description: 'Upload your PAN Card',
    },
    {
      href: 'visualauth',
      status: TokenService.getVisualAuthStatus(),
      title: 'Visual Authentication',
      description: 'Verify using video OTP',
    },
    {
      href: 'studentauth',
      status: TokenService.getStudentAuthStatus(),
      title: 'Student Authentication',
      description: 'Upload your student ID card',
    },
    {
      href: 'collegedocauth',
      status: TokenService.getCollegeDocAuthStatus(),
      title: 'College Document Authentication',
      description: 'Upload your college issued documents',
    },
  ];
//  if(!isMobile){
//    sessionStorage.setItem('/error',JSON.stringify(true));
//    history.push('/error');
//  }


//  axios.get("https://beta.api.services.stucred.com/student/student_info_m",config).then((result)=>{
//    console.log(result);
//
//  });
//  axios.get("https://beta.api.services.stucred.com/verification/verification_liveliness_otp",config).then((result)=>{
//    console.log(result);
//
//  });

  //const size = useWindowSize();
  TokenService.setWindowSize(useWindowSize());

  if (window.performance) {
    console.info("window.performance works fine on this browser");
  }
//  console.info(performance.navigation.type);
//  if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
//    console.info( "This page is reloaded: " +JSON.stringify(window.history));
//  } else {
//    console.info( "This page is not reloaded: "+JSON.stringify(window.history));
//    window.location.reload()
//  }

  //console.log(menuLists[0])
  //console.log("process.env: "+JSON.stringify(process.env))
  return (
    <>
      <Header />
      <div className="container home">
        {
          menuLists.map(({ href, status, title, description }, i) => (
            <Card key={i}>

              <NavLink to={href} style={{pointerEvents: status === true ? 'none' : ''}} >
                {title}
                <span>{description}</span>
                {status === true && <Tick />}
                {status === false && <Cancel />}
                <span className="right-arrow">&#8594;</span>
              </NavLink>
            </Card>
          ))
        }
      </div>
      <Footer />
    </>
  );
}
/*{status === true && <span className="tick">&#10003;</span>}
{status === false && <span className="cross">&#215;</span>}*/
/*<p className={'text-black'}>{title}</p>*/
/*<Card>
PAN Authentication
</Card>
<Card>
Visual Authentication
</Card>
<Card>
Student Authentication
</Card>
<Card>
College Document Authentication
</Card>*/


//-------Video------
//import React, { useCallback, useRef, useState } from "react";
//import { useNavigate } from 'react-router-dom';
//import Webcam from "react-webcam";
//
//import axios from 'axios';
//import getToken from '../components/useToken';
//
//
//export default function Video() {
//  const webcamRef = useRef(null);
//  const mediaRecorderRef = useRef(null);
//  const [capturing, setCapturing] = useState(false);
//  const [recordedChunks, setRecordedChunks] = useState([]);
//  const [successMessage, setSuccessMessage] = useState(false);
//  const [errorMessage, setErrorMessage] = useState(false);
//  const navigate = useNavigate();
//  const token = getToken();
//
//
//  const handleDataAvailable = useCallback(
//    ({ data }) => {
//      if (data.size > 0) {
//        setRecordedChunks((prev) => prev.concat(data));
//      }
//    },
//    [setRecordedChunks]
//  );
//
//  const handleStartCaptureClick = useCallback(() => {
//    setCapturing(true);
//    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
//      mimeType: "video/webm",
//    });
//    mediaRecorderRef.current.addEventListener(
//      "dataavailable",
//      handleDataAvailable
//    );
//    mediaRecorderRef.current.start();
//  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);
//
//  const handleStopCaptureClick = useCallback(() => {
//    mediaRecorderRef.current.stop();
//    const blob = new Blob(recordedChunks, {
//      type: "video/webm",
//    });
//    var reader = new FileReader();
//    reader.readAsDataURL(blob);
//    reader.onloadend = async function() {
//      var base64data = await reader.result;
//      if(blob.size > 0) {
//        looksgood(base64data)
//      }
//      console.log(base64data);
//    }
//    //const formData = new FormData(mediaRecorderRef.current);
//    setCapturing(false);
//  }, [mediaRecorderRef, setCapturing]);
//
//  async function looksgood (imageObject) {
//    await axios.post('https://beta.api.services.stucred.com/student-admin/image_upload?env=beta&fileName=visualAuth.mp4&cog_id=bb493a51-e815-4c1f-9869-33d183688ded&contentType=video/mp4&from=kycVisualAuth', {
//      //fileName: 'PANcardFront.jpg',
//      //contentType: 'image/jpeg',
//      //from: 'kycPan',
//      //env: 'beta',
//      data: imageObject,
//    },
//    {
//      headers: {
//        //"Access-Control-Allow-Credentials": true,
//        //"Access-Control-Allow-Origin": "*",
//        'Authorization': 'Bearer '+token.token,
//      }
//    })
//    .then(function (response) {
//      console.log(response);
//      setSuccessMessage(true)
//      setRecordedChunks([]);
//      const timeId = setTimeout(() => {
//        setSuccessMessage(false)
//        clearTimeout(timeId)
//        navigate('/', { replace: true });
//      }, 3000)
//    })
//    .catch(function (error) {
//      console.log(error);
//      setErrorMessage(true)
//      setRecordedChunks([]);
//      const timeId = setTimeout(() => {
//        setErrorMessage(false)
//        clearTimeout(timeId)
//      }, 3000)
//    })
//  }
//
//  const handleDownload = useCallback(() => {
//    if (recordedChunks.length) {
//      const blob = new Blob(recordedChunks, {
//        type: "video/webm",
//      });
//      const url = URL.createObjectURL(blob);
//      const a = document.createElement("a");
//      document.body.appendChild(a);
//      a.style = "display: none";
//      a.href = url;
//      a.download = "react-webcam-stream-capture.webm";
//      a.click();
//      window.URL.revokeObjectURL(url);
//      setRecordedChunks([]);
//    }
//  }, [recordedChunks]);
//
//  const videoConstraints = {
//    width: document.documentElement.clientWidth,
//    height: document.documentElement.clientHeight,
//    facingMode: "user",
//    screenshotQuality : 1,
//  };
//
//  return (
//    <div className="Container">
//      <Webcam
//        //height={420}
//        //width={800}
//        audio={true}
//        //mirrored={true}
//        ref={webcamRef}
//        videoConstraints={videoConstraints}
//      />
//      {capturing ? (
//        <button className="trigger" onClick={handleStopCaptureClick}>Stop Capture</button>
//      ) : (
//        <button className="trigger" onClick={handleStartCaptureClick}>Start Capture</button>
//      )}
//      {recordedChunks.length > 0 && (
//        <button onClick={handleDownload}>Download</button>
//      )}
//    </div>
//  );
//}

//PANcardFront.jpeg
//PANcardFront.jpg
//visualAuth.mp4
//StudentID.mp4
//StudentID2.mp4
//Document_3.jpg
//Document_2.jpg
//Document_1.jpg