import React from 'react';
import Card from './Card';

import './Failed.css'
import CancelIcon from './../../images/cancel-icon.svg';
//function Card(props){
const Failed = (props) => {
  //const classes = 'card ' + props.className;
  return (
//    <div className={ classes }>{ props.children }</div>
    <div className="error-container error-bg">
      <Card>
        <div className="error-msg">
        <img
          src={CancelIcon}
          alt="Loading..."
          className="img-fluid"
        />
        <h1>Failed</h1>
        <p>We are not received your upload request.<br/>Please try again!</p>
        </div>
      </Card>
    </div>
  );
}
export default Failed;
//<span className="error-cross">&#215;</span>