import { useState, useEffect } from "react";
import TimeOutBlocker from '../components/UI/TimeOutBlocker';
const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const IdleTimeOut = ({ children }) => {
  let timer;
  const [showIdleTimeOutBanner, setShowIdleTimeOutBanner] = useState(null);

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleTimer();
      });
    });
  }, []);

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const handleTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      //logoutAction();
      localStorage.clear();
      setShowIdleTimeOutBanner(true)
    //}, 100000000);
    }, 78000);
  };

  const logoutAction = () => {
    //localStorage.clear();
    //window.location.pathname = "/";
    //window.location = "https://stucred.page.link/Beta_UVScreen";
    //window.open('https://stucred.page.link/Beta_UVScreen','_parent','');
    window.open(process.env.REACT_APP_REDIRECTION_URL,'_parent','');
    //window.close();
  };
  return (
    <>
      { showIdleTimeOutBanner && <TimeOutBlocker onClick={logoutAction} title="Idle Time Out !!" buttonFirst="" buttonSecound="Go Back To APP"/> }
    </>
  );
  //return children;
};

export default IdleTimeOut;


