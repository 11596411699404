import React, { useCallback, useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Webcam from "react-webcam";
import adapter from "webrtc-adapter";

import './StudentAuth.css';
import TokenService from "../services/token.service";
import AuthService from "../services/auth.service";
import history from '../history';

import Success from '../components/UI/Success';
import Failed from '../components/UI/Failed';
import Loader from '../components/UI/Loader';
import FailedCamera from '../components/UI/FailedCamera';
import TimeOutBlocker from '../components/UI/TimeOutBlocker';

import turnUserID from './../images/turn-user-id.svg';
import focusImage from './../images/focus-image.svg';
import speakLouder from './../images/speak-louder.svg';

import useWindowSize from '../components/useWindowSize';

export default function StudentAuth() {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [videoUrl, setVideoUrl] = useState();
  const [videoScreen, setVideoScreen] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [timeOutBlocker, setTimeOutBlocker] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [stopButtonEnable, setStopButtonEnable] = useState(true);
  const [timer, setTimer] = useState('00');
  let downloadTimer;

  const userId = TokenService.getUserId();

  //useWindowSize
  const size = useWindowSize();
  //const isLandscape = size.height <= size.width;
  //const ratio = isLandscape ? size.width / size.height : size.height / size.width;
  const videoConstraints = {
    facingMode: 'environment',
    aspectRatio: size.ratio
  }

  console.log(videoConstraints)
  //useWindowSize

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  //const handleShowVideo = useEffect(() => {
  useEffect(() => {
      if (recordedChunks.length) {
        const blob = new Blob(recordedChunks, {
          type: "video/webm",
        });
        const audioURL = window.URL.createObjectURL(blob);
        setVideoUrl(audioURL);
      }
    }, [recordedChunks]
  );

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();

    /*countdown Timer*/
    var timeleft = 15;
    downloadTimer = setInterval(function(){
      //document.getElementById("progressBar").value = 10 - timeleft;
      console.log(timeleft)
      if(timeleft == 12) {setStopButtonEnable(false)}
      setTimer((15 - timeleft).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }))
      if(timeleft <= 0){
        clearInterval(downloadTimer);
//        mediaRecorderRef.current.stop();
//        mediaRecorderRef.current.removeEventListener(
//          "dataavailable",
//          handleDataAvailable
//        );
//        setCapturing(false);
//        setTimer('00');
        setTimeOutBlocker(true)
      }
      timeleft -= 1;
    }, 1000);
    /*countdown Timer*/

//    setTimeout(() => {
//      mediaRecorderRef.current.stop();
//      setCapturing(false);
//    }, 15000);
    /*https://stackoverflow.com/questions/73702988/how-to-take-webcam-snapshot-after-some-interval-in-stealth-mode-in-react*/

  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

/*
var timeleft = 10;
var downloadTimer = setInterval(function(){
  if(timeleft <= 0){
    clearInterval(downloadTimer);
  }
  console.log(10 - timeleft);
  timeleft -= 1;
}, 1000);
https://stackoverflow.com/questions/31106189/create-a-simple-10-second-countdown
https://www.freecodecamp.org/news/javascript-settimeout-js-timer-to-delay-n-seconds/#:~:text=If%20you%20want%20to%20repeatedly,JavaScript%20animations%20and%20online%20games.
*/
  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    clearInterval(downloadTimer);
    setCapturing(false);
    setStopButtonEnable(true)
  }, [mediaRecorderRef, setCapturing]);

  const uploadHandler = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async function() {
        var base64data = await reader.result;
        if(blob.size > 0) {
          looksGoodHandler(base64data)
        }
        console.log(base64data);
      }
      //setRecordedChunks([]);
    }
  }, [recordedChunks]);


  async function looksGoodHandler (imageObject) {
    setLoading(true);
    let videoName = videoScreen===2?'StudentID'+videoScreen+'.mp4':'StudentID.mp4';
    let param = 'env=beta&fileName='+videoName+'&cog_id='+userId+'&contentType=video/mp4&from=kycStudentAuth'
    AuthService.imageUpload(param,imageObject).then((response) => {
      setLoading(false);
      console.log(response);
      //if(videoScreen===0) {
      //  setVideoScreen(1)
      //}
      setSuccessMessage(true)
      if(videoScreen===0) {
        setVideoScreen(1)
        const timeId = setTimeout(() => {
          setSuccessMessage(false)
          clearTimeout(timeId)
          setRecordedChunks([]);
          setTimer('00')
        }, 3000)
      }
      if(videoScreen===2) {
        const timeId = setTimeout(() => {
          setSuccessMessage(false)
          clearTimeout(timeId)
          setRecordedChunks([]);
          setTimer('00');
          history.back();
          navigate('/', { replace: true });
        }, 3000)
      }

    }).catch((err) => {
      setLoading(false);
      //if(videoScreen===0) {
      //  setVideoScreen(1)
      //}
      //if(videoScreen===2) {
        setErrorMessage(true)
        const timeId = setTimeout(() => {
          setErrorMessage(false)
          setRecordedChunks([]);
          setTimer('00')
          clearTimeout(timeId)
        }, 3000)
      //}

    })
  }

  function reTakeHandler() {
    setRecordedChunks([]);
    setTimer('00');
    enableStartButton();
  }

  function onMediaErrorHandler(error) {
    if(error.name === 'NotAllowedError') {
      setPermissionDenied(true)
    }
  }
  function onMediaHandler(data) {
    setPermissionDenied(false)
    enableStartButton()
  }
  function assistHandler(){
    setPermissionDenied(false)
  }
  function enableStartButton() {
    setIsButtonDisabled(true)
    const timeIdESB = setTimeout(() => {
      setIsButtonDisabled(false)
      clearTimeout(timeIdESB)
    }, 1000)
  }
  function yesRedoHandler(data){
    mediaRecorderRef.current.stop();
    mediaRecorderRef.current.removeEventListener(
      "dataavailable",
      handleDataAvailable
    );
    setCapturing(false);
    setTimer('00');
    setTimeOutBlocker(false)
    if(data == false) {
      history.back();
      navigate('/', { replace: true });
    } else {
      handleStopCaptureClick()
    }
  }

//  useEffect(() => {
//    //window.addEventListener("beforeunload", handleBeforeUnload());
//    return () => {
//      window.removeEventListener("beforeunload", handleBeforeUnload());
//    };
//  }, []);

//  function handleBeforeUnload() {
//      console.log('******************* UNMOUNTED1');
//      navigate('/studentauth', { replace: true });
//  };
  useEffect(() => {
      return () => {
          console.log('******************* UNMOUNTED');
          window.onhashchange = function() {
              navigate('/studentauth', { replace: true });
          }
          //webcamRef.current.stop()
          //if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
          //  window.location.reload()
          //  console.info( "This page is reloaded: " +JSON.stringify(window.history));
          //} else {
          //  console.info( "This page is not reloaded: "+JSON.stringify(window.history));
          //  window.location.reload()
          //}
//        history.listen(function(data) {
//          console.log(data.location.pathname)
//        })

      };
  }, []);
  return (
    <>
      { successMessage && <Success /> }
      { errorMessage && <Failed /> }
      { loading && <Loader /> }
      { permissionDenied && <FailedCamera onClick={assistHandler}/> }
      { timeOutBlocker && <TimeOutBlocker onClick={yesRedoHandler} title="Time Out !!" text="Please redo the student authentication." buttonFirst="NO, CANCEL" buttonSecound="YES, REDO"/> }
      { !successMessage && !errorMessage && !permissionDenied && !loading && videoScreen === 0 && size.ratio > 0 && <div>
        { !recordedChunks.length > 0 && <div className="camera">
          <Webcam
            height = { size.height }
            width = { size.width }
            audio = { true }
            muted={true}
            screenshotFormat="image/webp"
            videoConstraints = { videoConstraints }
            ref = { webcamRef }
            onUserMedia = { (data) => onMediaHandler(data) }
            onUserMediaError = { (error) => onMediaErrorHandler(error) }
            //ref={camera => window.camera = camera}
          />
          <div className="focusImage">
            <img
              src={focusImage}
              alt="turn your ID card"
              className="img-fluid"
            />
          </div>
          <div className="recorder-time">
            <div className="icon"></div>
            00.{timer}
          </div>
          <div className="overlayTop">
            <div className="overlayBox">
              <div>Take a video of Student ID card</div>
              <span>Align your ID card within the frame</span>
            </div>
          </div>
          <div className="overlayBottom">
            <div className="overlayBox">
              <div className="speakLouder">
                <img
                  src={speakLouder}
                  alt="speak louder"
                  className="img-fluid"
                />
                Please s-l-o-w-l-y READ ALOUD the roll numner on your student ID card.
              </div>
              <div className="buttons">
                {capturing ? (
                  <button onClick={handleStopCaptureClick} disabled={stopButtonEnable}>Stop Capture</button>
                ) : (
                  <button className="capture" onClick={handleStartCaptureClick} disabled={isButtonDisabled}>Start Capture</button>
                )}
              </div>
            </div>
          </div>
        </div> }
      </div> }

      { !successMessage && !errorMessage && !permissionDenied && (videoScreen === 0 || videoScreen === 2) && <div>
        {recordedChunks.length > 0 && <div className="camera">
          <video src={videoUrl} controls height = { size.height - 80 } width = "100%"/>
          <div className="overlayBottom">
            <div className="overlayBox">
              <div className="buttons">
                {/*<button onClick={reTakeHandler}>Retake</button>*/}
                <button onClick={uploadHandler}>Looks Good</button>
              </div>
            </div>
          </div>
        </div> }
      </div> }

      { !successMessage && !errorMessage && !permissionDenied && videoScreen === 1 && <div>
        { !recordedChunks.length > 0 && <div className="camera">
          <div className="overlayTop">
            <div className="overlayBox">
              <div>Take a video of your Student ID card</div>
            </div>
          </div>
          <div className="turnCardScreen">
            <img
              src={turnUserID}
              alt="turn your ID card"
              className="img-fluid"
            />
          </div>
          <div className="overlayBottom">
            <div className="overlayBox">
              <div className="buttons">
                <button onClick={() => { setVideoScreen(videoScreen + 1); }}>Next</button>
              </div>
            </div>
          </div>
        </div> }
      </div> }

      { !successMessage && !errorMessage && !permissionDenied && !loading && videoScreen === 2 && size.ratio > 0 && <div>
        { !recordedChunks.length > 0 && <div className="camera">
          <Webcam
            height = { size.height }
            width = { size.width }
            audio = { true }
            muted={true}
            screenshotFormat="image/webp"
            videoConstraints = { videoConstraints }
            ref = { webcamRef }
            onUserMedia = { (data) => onMediaHandler(data) }
            onUserMediaError = { (error) => onMediaErrorHandler(error) }
            //ref={camera => window.camera = camera}
          />
          <div className="focusImage">
            <img
              src={focusImage}
              alt="turn your ID card"
              className="img-fluid"
            />
          </div>
          <div className="recorder-time">
            <div className="icon"></div>
            00.{timer}
          </div>
          <div className="overlayTop">
            <div className="overlayBox">
              <div>Take a video of Student ID card</div>
              <span>Align your ID card within the frame</span>
            </div>
          </div>
          <div className="overlayBottom">
            <div className="overlayBox">
              <div className="speakLouder">
                <img
                  src={speakLouder}
                  alt="speak louder"
                  className="img-fluid"
                />
                Please s-l-o-w-l-y READ ALOUD the DOB on the ID card if printed.
              </div>
              <div className="buttons">
                {capturing ? (
                  <button onClick={handleStopCaptureClick} disabled={stopButtonEnable}>Stop Capture</button>
                ) : (
                  <button className="capture" onClick={handleStartCaptureClick} disabled={isButtonDisabled}>Start Capture</button>
                )}
              </div>
            </div>
          </div>
        </div> }
      </div> }
    </>
  );
}
//{recordedChunks.length > 0 && <div className="camera">
//  <video src={videoUrl} controls height = { size.height - 80 } width = "100%"/>
//  <div className="overlayBottom">
//    <div className="overlayBox">
//      <div className="buttons">
//        <button onClick={reTakeHandler}>Retake</button>
//        <button onClick={uploadHandler}>Looks Good</button>
//      </div>
//    </div>
//  </div>
//</div> }
//<video src={videoUrl} controls height = { size.height } width = { size.width }/>

//https://blog.openreplay.com/capture-real-time-images-and-videos-with-react-webcam/
//export default function About() {
//  const videoRef = useRef(null);
//
//    useEffect(() => {
//      getVideo();
//    }, [videoRef]);
//
//    const getVideo = () => {
//      navigator.mediaDevices
//        .getUserMedia({ video: { width: 300 } })
//        .then(stream => {
//          let video = videoRef.current;
//          video.srcObject = stream;
//          video.play();
//        })
//        .catch(err => {
//          console.error("error:", err);
//        });
//    };
//
//    return (
//      <div>
//        <div>
//          <button>Take a photo</button>
//          <video ref={videoRef} />
//        </div>
//      </div>
//    );
//  };