import React from 'react';
import Card from './Card';

import './Success.css'
import TickIcon from './../../images/tick-icon.svg';
//function Card(props){
const Success = (props) => {
  //const classes = 'card ' + props.className;
  return (
//    <div className={ classes }>{ props.children }</div>
    <div className="success-container success-bg">
      <Card>
        <div className="success-msg">
          <img
            src={TickIcon}
            alt="Loading..."
            className="img-fluid"
          />
          <h1>Success</h1>
          <p>We received your upload request.<br/>we will be in touch shortly!</p>
        </div>
      </Card>
    </div>
  );
}
export default Success;
//<span className="success-tick">&#10003;</span>