import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import adapter from "webrtc-adapter";
//"webrtc-adapter": "^8.2.3"
import useEffectOnce from "../utilities/useEffectOnce"
import TokenService from "../services/token.service";
import AuthService from "../services/auth.service";
import history from '../history';

import './PanAuthentication.css';
import Success from '../components/UI/Success';
import Failed from '../components/UI/Failed';
import Loader from '../components/UI/Loader';
import FailedCamera from '../components/UI/FailedCamera';

import focusImage from './../images/focus-image.svg';

export default function PanAuthentication() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imageObject, setImageObject] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  //const [mediaStream, setMediaStream] = useState(null);
  const [facingMode, setFacingMode] = useState('environment'); // Initialize with 'user/environment' (front/Back camera)
  //var imageCapture;


  const userId = TokenService.getUserId();

  //useWindowSize
  const size = TokenService.getWindowSize();
//  const videoConstraints = {
//      audio: true,
//      muted: true,
//      video: {
//          height: size.width,
//          width: size.height,
//          facingMode: facingMode,
//          aspectRatio: size.ratio,
//      }
//  }
  const videoConstraints = {
    audio: true,
    muted: true,
    video: {
      width: 320, height: 240, facingMode: facingMode,
    }
  };
  console.log(videoConstraints)
  //useWindowSize

  useEffectOnce(() => {handleOpenCamera()})

  const handleOpenCamera = async () => {
    //https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
    await navigator.mediaDevices
    //.getUserMedia({ video: videoConstraints, audio: true, muted: true, })
    .getUserMedia(videoConstraints)
    .then((stream) => {
      /* use the stream */
      window.localStream = stream;
      videoRef.current.srcObject = stream;
      //videoRef.current.play();
      //const track = stream.getVideoTracks()[0];
      //imageCapture = new ImageCapture(track);
      //setMediaStream(stream);
      onMediaHandler()
    })
    .catch((error) => {
      if (error.name === "OverconstrainedError") {
        console.error(
          `The resolution ${videoConstraints.video.width.exact}x${videoConstraints.video.height.exact} px is not supported by your device.`,
        );
      } else if (error.name === "NotAllowedError") {
        console.error(
          "You need to grant this page permission to access your camera and microphone.",
        );
      } else {
        console.error(`getUserMedia error: ${error.name}`, error);
      }
      onMediaErrorHandler(error)
    });
    //.catch((err) => {
    //  /* handle the error */
    //  onMediaErrorHandler(err)
    //});
  };

//  const capture = React.useCallback(
//    () => {
//        //const canvas = canvasRef.current;
//        //const contex = canvas.getContext('2d');
//        //contex.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
//        //console.log(canvas.toDataURL());
//        //setImageObject(canvas.toDataURL())
//        //videoRef.current.srcObject.getTracks().forEach((track) => {
//        //  track.stop();
//        //});
//
//      //https://www.studytonight.com/code/playground/web/?id=RbKnX9
//
//      //const canvas: HTMLCanvasElement | null = null;
//      //const ctx: CanvasRenderingContext2D | null = null;
//      const canvas = canvasRef.current;
//      const ctx = canvas.getContext('2d');
//      let canvasWidth = videoRef.current.videoHeight;
//      let canvasHeight = videoRef.current.videoWidth;
//      const aspectRatio = canvasWidth / canvasHeight;
//      //canvasWidth = videoRef.current.clientWidth;
//      //canvasHeight = canvasWidth / aspectRatio;
//      canvas.width = canvasWidth;
//      canvas.height = canvasHeight;
//      //ctx = canvas.getContext("2d");
//      ctx.imageSmoothingEnabled = true;
//      ctx.drawImage(videoRef.current, 0, 0, canvasWidth, canvasHeight);
//      console.log(canvas.toDataURL("image/webp", 0.92))
//      setImageObject(canvas.toDataURL("image/webp", 0.92))
//      videoRef.current.srcObject.getTracks().forEach((track) => {
//        track.stop();
//      });
//
////      const track = videoRef.current.srcObject.getVideoTracks()[0]
////      let imageCapture = new ImageCapture(track);
////      imageCapture.takePhoto()
////        .then(blob => {
////          videoRef.current.srcObject.getTracks().forEach((track) => {
////            track.stop();
////          });
////          var reader = new FileReader();
////          reader.readAsDataURL(blob);
////          reader.onloadend = async function() {
////            var base64data = await reader.result;
////            if(blob.size > 0) {
////              setImageObject(base64data)
////            }
////            console.log(base64data);
////          }
////        })
////        .catch(error => onMediaErrorHandler(error));
//    },
//    [videoRef, setImageObject]
//  );

  function setInRange(value, range) {
      if(!range) return NaN;
      let x = Math.min(range.max, Math.max(range.min, value));
      x = Math.round(x / range.step) * range.step; // take `step` into account
      return x;
  }
  const capture = React.useCallback(
      async () => {
          const track = videoRef.current.srcObject.getVideoTracks()[0]
          let imageCapture = new ImageCapture(track);
          const { imageWidth, imageHeight } = await imageCapture.getPhotoCapabilities();
          const width = setInRange(size.width, imageWidth);
          const height = setInRange(size.height, imageHeight);
          const photoSettings = (width && height) ? {
            imageWidth: width,
            imageHeight: height
          } : null;

          imageCapture.takePhoto(photoSettings)
              .then(blob => {
                  videoRef.current.srcObject.getTracks().forEach((track) => {
                    track.stop();
                  });
                  var reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = async function() {
                      var base64data = await reader.result;
                      if(blob.size > 0) {
                          setImageObject(base64data)
                      }
                      //console.log(base64data);
                  }
              })
              .catch(error => onMediaErrorHandler(error));
      },
      [videoRef, setImageObject]
  );

  function reTakeHandler() {
    setImageObject(null)
    handleOpenCamera()
    enableStartButton()
  }

  async function looksGoodHandler () {
    setLoading(true);
    let param = 'env=beta&fileName=PANcardFront.jpg&cog_id='+userId+'&contentType=image/jpeg&from=kycPan'
    AuthService.imageUpload(param,imageObject).then((data) => {
      AuthService.panUpdate(data).then(() => {
        setLoading(false);
        setSuccessMessage(true)
        setImageObject(null)
        const timeId = setTimeout(() => {
          setSuccessMessage(false)
          clearTimeout(timeId)
          history.back();
          navigate('/', { replace: true });
        }, 3000)
      }).catch((err) => {
        console.log(err);
        setLoading(false);
        setImageObject(null)
        setErrorMessage(true)
        const timeId = setTimeout(() => {
          setErrorMessage(false)
          clearTimeout(timeId)
          handleOpenCamera()
        }, 3000)
      })
    }).catch((err) => {
      console.log(err);
      setLoading(false);
      setImageObject(null)
      setErrorMessage(true)
      const timeId = setTimeout(() => {
        setErrorMessage(false)
        clearTimeout(timeId)
        handleOpenCamera()
      }, 3000)
    })
  }
  function onMediaErrorHandler(error) {
    if(error.name === 'NotAllowedError') {
      setPermissionDenied(true)
    }
  }
  function onMediaHandler(data) {
    setPermissionDenied(false)
    enableStartButton()
  }
  function assistHandler(){
    setPermissionDenied(false)
    handleOpenCamera()
  }
  function enableStartButton() {
      setIsButtonDisabled(true)
      const timeIdESB = setTimeout(() => {
          setIsButtonDisabled(false)
          clearTimeout(timeIdESB)
      }, 1000)
  }
  useEffect(() => {
      //window.scrollTo(0, 1);
      window.addEventListener('popstate', function(event) {
          // The popstate event is fired each time when the current history entry changes.
          window.localStream.getTracks().forEach((track) => {
              track.stop();
          });
      }, false);
      return () => {
          console.log('******************* UNMOUNTED');
          window.onhashchange = function() {
              navigate('/pan-authentication', { replace: true });
          }
      };
  }, []);
  //<canvas ref={canvasRef} width={size.width} height={size.height}/>
  return (
  <>
    { successMessage && <Success /> }
    { errorMessage && <Failed /> }
    { loading && <Loader /> }
    { permissionDenied && <FailedCamera onClick={assistHandler}/> }
    { !successMessage && !errorMessage && !permissionDenied && <div>
      { !imageObject && <div className="camera card-shape">
        <div className="overlayTop">
          <div className="overlayBox">
            <div>Take a photo of your PAN card FRONT</div>
          </div>
        </div>
        <video ref={videoRef} autoPlay playsInline muted />
        <div className="focusImage">
          <img
            src={focusImage}
            alt="turn your ID card"
            className="img-fluid"
          />
        </div>
        <div className="overlayBottom">
          <div className="overlayBox">
            <div className="text-center">Place your PAN card on a flat surface and within the borders of the frame to ensure that the image is clear with no glare of light.</div>
            <div className="buttons">
              <button className="capture" onClick={capture} disabled={isButtonDisabled}>Capture</button>
            </div>
          </div>
        </div>
      </div> }
    </div> }

    { !successMessage && !errorMessage && !permissionDenied && <div>
      {imageObject && <div className="camera">
        <img src={imageObject} alt="" />
        <div className="overlayTop">
          <div className="overlayBox">
            <div>Take a photo of your PAN card FRONT</div>
          </div>
        </div>
        <div className="overlayBottom">
          <div className="overlayBox">
            <div className="text-center">Is this image of your Permanent Account Number(PAN) card FRONT clear?</div>
            <div className="buttons">
              <button onClick={reTakeHandler}>Retake</button>
              <button onClick={looksGoodHandler}>Looks Good</button>
            </div>
          </div>
        </div>
      </div> }
    </div> }
  </>
  );
}
//<button className="retake" onClick={reTakeHandler}>Retake</button>
//<button className="looksgood" onClick={looksGoodHandler}>Looks Good</button>