import React from 'react';
import Card from './Card';

import './FailedCamera.css'

//function Card(props){
const FailedCamera = (props) => {
  //const classes = 'card ' + props.className;
  return (
//    <div className={ classes }>{ props.children }</div>
<>
    <div className="failed-camera-container error-bg">
      <Card>
        <div className="error-msg">
          <span className="error-cross">📷</span>
          <h1>Camera Blocked, Allow Access</h1>
          <div className="text-left">
            <h4>We need to access the camera to complete your KYC process</h4>
            <p>Follow theses steps to use camera</p>
            <div>
                <ol>
                    <li>Go to <strong>Settings</strong> in your chrome browser.</li>
                    <li>In <strong>Advanced</strong> section, Select <strong>Site Settings.</strong></li>
                    <li>Then choose <strong>Camera</strong>.</li>
                    <li>Now on this page, In the <strong>Blocked</strong> section, you can see the web address. Click on it and allow <strong>Access your camera</strong> option.</li>
                    <li>Thank You for your Assistance.</li>
                </ol>
            </div>
            <p className="text-center">or</p>
            <div>
                <ol>
                    <li>Click on the <strong>lock</strong> icon on the Address bar in your browser.</li>
                    <li>Allow <strong>Access to the camera</strong>.</li>
                </ol>
            </div>
          </div>
          <div className="text-center">
            <button type="button" className="btn btn-success"  onClick={props.onClick}>Go Back to Camera</button>
          </div>
        </div>
      </Card>
    </div>
  </>
  );
}
export default FailedCamera;
//<button type="button" className="btn btn-success" onClick={assistHandler}>Go Back to Camera</button>