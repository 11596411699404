import React, { useCallback, useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Webcam from "react-webcam";
import adapter from "webrtc-adapter";

import './VisualAuth.css';
import TokenService from "../services/token.service";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import history from '../history';

import Success from '../components/UI/Success';
import Failed from '../components/UI/Failed';
import Loader from '../components/UI/Loader';
import FailedCamera from '../components/UI/FailedCamera';
import TimeOutBlocker from '../components/UI/TimeOutBlocker';

import focusFaceImage from './../images/focus-face-image.svg';
import speakLouder from './../images/speak-louder.svg';

import useWindowSize from '../components/useWindowSize';

export default function VisualAuth() {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [videoUrl, setVideoUrl] = useState();
  const [videoOtp, setVideoOtp] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOTPLoading] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [timeOutBlocker, setTimeOutBlocker] = useState(false);
  const [timer, setTimer] = useState('00');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [stopButtonEnable, setStopButtonEnable] = useState(true);
  const OTPGenerate = useRef(null);
  let downloadTimer;

  const userId = TokenService.getUserId();

  //useWindowSize
  const size = useWindowSize();
  //const isLandscape = size.height <= size.width;
  //const ratio = isLandscape ? size.width / size.height : size.height / size.width;
  const videoConstraints = {
    facingMode: 'user',
    aspectRatio: size.ratio
  }

  console.log(videoConstraints)
  //useWindowSize

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  //const handleShowVideo = useEffect(() => {
  useEffect(() => {
      if (recordedChunks.length) {
        const blob = new Blob(recordedChunks, {
          type: "video/webm",
        });
        const audioURL = window.URL.createObjectURL(blob);
        setVideoUrl(audioURL);
      }
    }, [recordedChunks]
  );

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();

    /*countdown Timer*/
    var timeleft = 15;
    downloadTimer = setInterval(function(){
      //document.getElementById("progressBar").value = 10 - timeleft;
      if(timeleft == 12) {setStopButtonEnable(false)}
      setTimer((15 - timeleft).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }))
      if(timeleft <= 0){
        clearInterval(downloadTimer);
//        mediaRecorderRef.current.stop();
//        mediaRecorderRef.current.removeEventListener(
//          "dataavailable",
//          handleDataAvailable
//        );
//        setCapturing(false);
//        setTimer('00')
        setTimeOutBlocker(true)
      }
      timeleft -= 1;
    }, 1000);
    /*countdown Timer*/
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    clearInterval(downloadTimer);
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  const uploadHandler = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async function() {
        var base64data = await reader.result;
        if(blob.size > 0) {
          looksGoodHandler(base64data)
        }
        console.log(base64data);
      }
      //setRecordedChunks([]);
    }
  }, [recordedChunks]);

  async function looksGoodHandler (imageObject) {
    setLoading(true);
    let param = 'env=beta&fileName=visualAuth.mp4&cog_id='+userId+'&contentType=video/mp4&from=kycVisualAuth'
    AuthService.imageUpload(param,imageObject).then((data) => {
      setLoading(false);
      setSuccessMessage(true)
      const timeId = setTimeout(() => {
        setSuccessMessage(false)
        clearTimeout(timeId)
        setRecordedChunks([]);
        setTimer('00');
        history.back();
        navigate('/', { replace: true });
        //window.location.reload();
      }, 3000)
    }).catch((err) => {
      console.log(err);
      setLoading(false);
      setErrorMessage(true)
      const timeId = setTimeout(() => {
        setErrorMessage(false)
        clearTimeout(timeId)
        setRecordedChunks([]);
        setTimer('00')
      }, 3000)
    })
  }

  function reTakeHandler() {
    setRecordedChunks([]);
    setTimer('00');
    enableStartButton();
  }

  function generateVideoOTPHandler() {
    setLoading(true);
    setOTPLoading(true)
    UserService.getVerificationLivelinessOTP().then((response) => {
      setLoading(false);
      setOTPLoading(false)
      //console.log(response);
      setVideoOtp(response.data.Verification.otp)
      OTPGenerate.current = response.data.Verification.otp;
    }).catch((err) => {
      console.log(err);
      setLoading(false);
      setOTPLoading(false)
      setErrorMessage(true)
      const timeId = setTimeout(() => {
        setErrorMessage(false)
        history.back();
        navigate('/', { replace: true });
        //window.location.reload();
        clearTimeout(timeId)
      }, 3000)
    })
  }

  function onMediaErrorHandler(error) {
    //console.log(error instanceof TypeError)
    //console.log(error instanceof ReferenceError); // "Hello"
    //console.log(error instanceof SyntaxError); // "Hello"
    //console.log(error instanceof URIError); // "Hello"
    //console.log(error instanceof EvalError); // "Hello"
    //console.log(error.name); // "TypeError"
    //console.log(error instanceof RangeError); // Stack of the error
    //console.log(error)
    setPermissionDenied(true)
//    if(error.name === 'NotAllowedError') {
//      //alert('error: '+error.name);
//      setPermissionDenied(true)
//    }
  }
  function onMediaHandler(data) {
    //alert(data);
    setPermissionDenied(false)
    if(OTPGenerate.current === null) {
      generateVideoOTPHandler()
    }
    enableStartButton()
  }
  function assistHandler(){
    setPermissionDenied(false)
  }
  function enableStartButton() {
    setIsButtonDisabled(true)
    const timeIdESB = setTimeout(() => {
      setIsButtonDisabled(false)
      clearTimeout(timeIdESB)
    }, 1000)
  }
  function yesRedoHandler(data){
    mediaRecorderRef.current.stop();
    mediaRecorderRef.current.removeEventListener(
      "dataavailable",
      handleDataAvailable
    );
    setCapturing(false);
    setTimer('00');
    setTimeOutBlocker(false)
    if(data == false) {
      history.back();
      navigate('/', { replace: true });
    } else {
      handleStopCaptureClick()
    }
  }
  useEffect(() => {
      return () => {
          console.log('******************* UNMOUNTED');
          window.onhashchange = function() {
              navigate('/visualauth', { replace: true });
          }
      };
  }, []);
  return (
    <>
      { successMessage && <Success /> }
      { errorMessage && <Failed /> }
      { loading && <Loader /> }
      { permissionDenied && <FailedCamera onClick={assistHandler}/> }
      { timeOutBlocker && <TimeOutBlocker onClick={yesRedoHandler} title="Time Out !!" text="Please redo the student authentication." buttonFirst="NO, CANCEL" buttonSecound="YES, REDO"/> }
      { !successMessage && !errorMessage && !permissionDenied && (!loading || otpLoading) && size.ratio > 0 && <div>
        { !recordedChunks.length > 0 && <div className="camera">
          <Webcam
            height = { size.height }
            width = { size.width }
            audio = { true }
            muted={true}
            screenshotFormat="image/webp"
            videoConstraints = { videoConstraints }
            ref = { webcamRef }
            onUserMedia = { (data) => onMediaHandler(data) }
            onUserMediaError = { (error) => onMediaErrorHandler(error) }
            //ref={camera => window.camera = camera}
          />
          <div className="focusFaceImage">
            <img
              src={focusFaceImage}
              alt="turn your ID card"
              className="img-fluid"
            />
          </div>
          <div className="recorder-time">
            <div className="icon"></div>
            00.{timer}
          </div>
          <div className="overlayTop">
            <div className="overlayBox">
              <div>Visual authentication using video OTP</div>
              <span>Align your face within the outline</span>
            </div>
          </div>
          <div className="overlayBottom">
            <div className="overlayBox">
              <div className="speakLouder">
                <img
                  src={speakLouder}
                  alt="speak louder"
                  className="img-fluid"
                />
                Please s-l-o-w-l-y READ ALOUD the number displayed below.
              </div>
              <div className="videoOtp">{videoOtp}</div>
              <div className="buttons">
                {capturing ? (
                  <button onClick={handleStopCaptureClick} disabled={stopButtonEnable}>Stop Capture</button>
                ) : (
                  <button className="capture" onClick={handleStartCaptureClick} disabled={isButtonDisabled}>Start Capture</button>
                )}
              </div>
            </div>
          </div>
        </div> }
      </div> }
      { !successMessage && !errorMessage && !permissionDenied && <div>
        {recordedChunks.length > 0 && <div className="camera">
          <video src={videoUrl} controls height = { size.height - 80 } width = "100%"/>
          <div className="overlayBottom">
            <div className="overlayBox">
              <div className="buttons">
                {/*<button onClick={reTakeHandler}>Retake</button>*/}
                <button onClick={uploadHandler}>Looks Good</button>
              </div>
            </div>
          </div>
        </div> }
      </div> }
    </>
  );
}
//<button onClick={handleStartCaptureClick}>Start Capture</button>
//<video src={videoUrl} controls height = { size.height } width = { size.width }/>

//https://blog.openreplay.com/capture-real-time-images-and-videos-with-react-webcam/
//export default function About() {
//  const videoRef = useRef(null);
//
//    useEffect(() => {
//      getVideo();
//    }, [videoRef]);
//
//    const getVideo = () => {
//      navigator.mediaDevices
//        .getUserMedia({ video: { width: 300 } })
//        .then(stream => {
//          let video = videoRef.current;
//          video.srcObject = stream;
//          video.play();
//        })
//        .catch(err => {
//          console.error("error:", err);
//        });
//    };
//
//    return (
//      <div>
//        <div>
//          <button>Take a photo</button>
//          <video ref={videoRef} />
//        </div>
//      </div>
//    );
//  };