import React from 'react';
import loader from './../../images/cancel-icon.svg';
import './Cancel.css'

//function Card(props){
export default function Cancel() {
//const Loader = (props) => {
  //const classes = 'card ' + props.className;
  return (
    <div className="cross">
      <img
        src={loader}
        alt="Loading..."
        className="img-fluid"
      />
    </div>
  );
}
//export default Loader;