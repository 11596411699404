import React from 'react';
import { Routes, Route, useSearchParams } from "react-router-dom";
import './AppRoutes.css';

import Login from "./Pages/Login"
import Home from "./Pages/Home"
import Pan from "./Pages/Pan"
import VisualAuth from "./Pages/VisualAuth"
import StudentAuth from "./Pages/StudentAuth"
import CollegeDocAuth from "./Pages/CollegeDocAuth"

import StudentAuthentication from "./Pages/StudentAuthentication"
//import StuAuthExp from "./Pages/StuAuthExp"
import VisualAuthentication from "./Pages/VisualAuthentication"
import PanAuthentication from "./Pages/PanAuthentication"
import CollegeDocumentAuthentication from "./Pages/CollegeDocumentAuthentication"

//setha
//import StudentAuthNew from "./Pages/StudentAuthNew"
//import StudentAuthNew1 from "./Pages/StudentAuthNew1"

import PrivateRoute from "./components/privateRoute"
import TokenService from "./services/token.service";

import history from './history';
import IdleTimeOut from "./utilities/IdleTimeOut"
export default function AppRoutes() {
  let [searchParams] = useSearchParams();
  const logSearchParams = () => {
    return searchParams.get("id")
  };
  //const param = logSearchParams()
  const token = TokenService.getAccessToken(logSearchParams());

  if(!token) {
    //return <Login setToken={setToken} />
    return <Login />
  }
  return (
      <>
          <IdleTimeOut/>
          <Routes history={history}>
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<Home />} />
                <Route path="pan" element={<Pan />} />
                <Route path="VisualAuth" element={<VisualAuth />} />
                <Route path="StudentAuth" element={<StudentAuth />} />
                <Route path="CollegeDocAuth" element={<CollegeDocAuth />} />
                <Route path="student-authentication" element={<StudentAuthentication />} />
                <Route path="visual-authentication" element={<VisualAuthentication />} />
                <Route path="pan-authentication" element={<PanAuthentication />} />
                <Route path="college-document-authentication" element={<CollegeDocumentAuthentication />} />
                <Route path="*" element={<Home />} />
              </Route>
          </Routes>
      </>
  );
}

//Login.propTypes = {
//  setToken: PropTypes.func.isRequired
//};