//import React from 'react';
//import { Route, Navigate } from 'react-router-dom';
//
//const PrivateRoute = ({path:pagePath, component: Component, ...rest}) => {
//    let temp = sessionStorage.getItem('currentPage');
//    let buttonTriggered = JSON.parse(sessionStorage.getItem(pagePath));
//    return(
//        <Route path={pagePath} {...rest} render={props => (
//            (buttonTriggered) ?
//                <Component {...props}/>
//                //:<Redirect to={temp}></Redirect>
//                : <Navigate to={temp} replace />
//            )
//        }/>
//    );
//};
//
//export default PrivateRoute;

import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ component: component, ...rest }) => {
  const token = localStorage.getItem("token");
  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
