///kreon@1994
//import React, {useState} from 'react';
//import React from 'react';
import React, { useEffect } from "react";
//import { useMobileOrientation, isMobileOnly } from 'react-device-detect';
import { isMobileOnly } from 'react-device-detect';

//import Webcam from "react-webcam";
import './App.css';
import AppRoutes from './AppRoutes';
//import RotateYourDevice from './components/RotateYourDevice';
import NotAMobileDevice from './components/NotAMobileDevice';
//import Card from './components/UI/Card';

//import axios from "./axios";
//
//const fetchPost = async (id) => {
//  const { data } = await axios.get("/posts/" + id);
//  return data;
//};
export default function App() {
//  const [postId, setPostId] = useState(1);
//  const [isLoading, setIsLoading] = useState(false);
//  const [posts, setPosts] = useState([]);
//
//  const fetchPostHandler = async () => {
//    setIsLoading(true);
//    const post = await fetchPost(postId);
//    setIsLoading(false);
//    setPosts((prev) => [...prev, post]);
//    setPostId((prev) => prev + 1);
//  };

  //Track or detect number of tabs open for same website in React
  //useEffect(() => {
  //
  //    // define increment counter part
  //    const tabsOpen = localStorage.getItem('tabsOpen')
  //    console.log('tabsOpen', tabsOpen)
  //    if (tabsOpen == null) {
  //        localStorage.setItem('tabsOpen', 1)
  //    } else {
  //        localStorage.setItem('tabsOpen', parseInt(tabsOpen) + parseInt(1))
  //    }
  //
  //    // define decrement counter part
  //    window.onunload = function (e) {
  //        const newTabCount = localStorage.getItem('tabsOpen')
  //        if (newTabCount !== null) {
  //            localStorage.setItem('tabsOpen', newTabCount - 1)
  //        }
  //    }
  //}, [])
  //Track or detect number of tabs open for same website in React

//  window.onbeforeunload = function(e){
//    window.close();
//  }

  // Check for a query parameter indicating that tabs should be reloaded
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('reloadTabs')) {
    // Get the list of URLs to reload (you would need to implement this part)
    const urlsToReload = window.getUrlsToReload(); // Implement this function to retrieve the URLs you stored in your app

    // Reload each URL
    urlsToReload.forEach(url => {
      const tab = window.open(url, '_blank');
      if (tab) {
        tab.location.reload();
      }
    });
  }
   // Check for a query parameter indicating that tabs should be reloaded

  if(!isMobileOnly) {
    return (<NotAMobileDevice />);
  } else {
    return (<AppRoutes />);
  }
//  const { isLandscape } = useMobileOrientation()
//  if(!isMobileOnly) {
//    return (<NotAMobileDevice />);
//  } else {
//    if (!isLandscape) {
//      return (<AppRoutes />);
//    } else {
//      return (
//        <RotateYourDevice />
//      );
//    }
//  }
//  return (
////    <>
////    <ul>
////      {posts?.map((post) => (
////        <li key={post.id}>{post.title}</li>
////      ))}
////    </ul>
////    {isLoading && <p>loading ...</p>}
////    <button onClick={fetchPostHandler}>fetch next post</button>
//      <AppRoutes />
////    </>
//  );
}

/*
https://www.npmjs.com/package/dotenv#-install
https://www.tutorialspoint.com/reactjs/reactjs_environment_setup.htm
https://stackblitz.com/edit/react-j6l3kt?file=index.js
*/