import React from 'react';
import './Header.css';
import logo from '../../stucred-logo.png';

export default function Header() {
  return (
    <div className="header">
      <img
        src={logo}
        alt="StuCred® | Real-Time Student Credit | Logo"
        className="headerLogo"
      />
    </div>
  )
}