import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Webcam from "react-webcam";
import adapter from "webrtc-adapter";

import useWindowSize from '../components/useWindowSize';
import TokenService from "../services/token.service";
import AuthService from "../services/auth.service";
import history from '../history';

import './CollegeDocAuth.css';
import Card from '../components/UI/Card';
import Success from '../components/UI/Success';
import Failed from '../components/UI/Failed';
import Loader from '../components/UI/Loader';
import FailedCamera from '../components/UI/FailedCamera';

import focusImage from './../images/focus-image.svg';

export default function CollegeDocAuth() {
  const webcamRef = useRef(null);
  const [imageObject, setImageObject] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [photoScreen, setPhotoScreen] = useState(1);
  const [documentCount, setDocumentCount] = useState(1);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  //const token = getToken();
  //console.log(token)
  const userId = TokenService.getUserId();

  //useWindowSize
  const size = useWindowSize();
  //const isLandscape = size.height <= size.width;
  //const ratio = isLandscape ? size.width / size.height : size.height / size.width;
  const videoConstraints = {
    facingMode: 'environment',
    aspectRatio: size.ratio
  }

  const capture = React.useCallback(
    () => {
      setImageObject(webcamRef.current.getScreenshot());
      //setImageObject(window.camera.getScreenshot());
    },
    [webcamRef, setImageObject]
  );

  function reTakeHandler() {
    setImageObject(null)
    enableStartButton()
  }

  async function looksGoodHandler () {
    setLoading(true);
    let photoName = 'Document_'+documentCount+'.jpg';
    let param = 'env=beta&fileName='+photoName+'&cog_id='+userId+'&contentType=image/jpeg&from=kycCollegeDocAuth&name=Document '+documentCount
    AuthService.imageUpload(param,imageObject).then((response) => {
      setLoading(false);
      setSuccessMessage(true)
      setImageObject(null)
      const timeId = setTimeout(() => {
        setSuccessMessage(false)
        clearTimeout(timeId)
        if(documentCount === 3) {
          history.back();
          navigate('/', { replace: true });
        }
        setDocumentCount(documentCount + 1);
      }, 3000)
//      setLoading(false);
//      console.log(response);
//      if(photoScreen===3) {
//        setSuccessMessage(true)
//        const timeId = setTimeout(() => {
//          setSuccessMessage(false)
//          clearTimeout(timeId)
//          if(documentCount === 3) {
//            navigate('/', { replace: true });
//          }
//          setDocumentCount(documentCount + 1);
//        }, 3000)
//        setPhotoScreen(2)
//      }
      //setRecordedChunks([]);
//      setImageObject(null)
    }).catch((err) => {
      console.log(err);
      setLoading(false);
      setImageObject(null)
      setErrorMessage(true)
      const timeId = setTimeout(() => {
        setErrorMessage(false)
        clearTimeout(timeId)
      }, 3000)
    })
//    }).catch((err) => {
//      setLoading(false);
//      if(photoScreen===3) {
//        setErrorMessage(true)
//        const timeId = setTimeout(() => {
//          setErrorMessage(false)
//          clearTimeout(timeId)
//        }, 3000)
//      }
//      //setRecordedChunks([]);
//      setImageObject(null)
//    })
  }

  function onMediaErrorHandler(error) {
    if(error.name === 'NotAllowedError') {
      setPermissionDenied(true)
    }
  }
  function onMediaHandler(data) {
    setPermissionDenied(false)
    enableStartButton()
  }
  function assistHandler(){
    setPermissionDenied(false)
  }
  function enableStartButton() {
    setIsButtonDisabled(true)
    const timeIdESB = setTimeout(() => {
      setIsButtonDisabled(false)
      clearTimeout(timeIdESB)
    }, 1000)
  }
  useEffect(() => {
      return () => {
          console.log('******************* UNMOUNTED');
          window.onhashchange = function() {
              navigate('/collegedocauth', { replace: true });
          }
      };
  }, []);

  const menuLists = [
    {
      href: 'pan',
      doc: 1,
      title: 'Document 1',
      description: 'College issued Document',
    },
    {
      href: 'visualauth',
      doc: 2,
      title: 'Document 2',
      description: 'College issued Document',
    },
    {
      href: 'studentauth',
      doc: 3,
      title: 'Document 3',
      description: 'College issued Document',
    }
  ];

  return (
  <>
    { successMessage && <Success /> }
    { errorMessage && <Failed /> }
    { loading && <Loader /> }
    { permissionDenied && <FailedCamera onClick={assistHandler}/> }
    { !successMessage && !errorMessage && !permissionDenied && photoScreen === 1 && <div>
      { imageObject == null && <div className="container">
        <div className="font-size-14">
          <p className="font-size-18 font-weight-bold mb-0">Upload Documents</p>
          <p className="dec font-size-12 mt-0">Upload any of three of the following documents</p>
          <p className="font-weight-bold">You may capture and upload up to 3 College- issued documents to prove you are currently enrolled in a college. Your document must display:</p>
          <ul>
            <li>Your first name(Rahul)</li>
            <li>Your last name(Sharma)</li>
            <li>The full name of college/university you are currently attending(Nirma University Ahmedabad, Gujarat)</li>
            <li>Must show an issue date within last 3 months</li>
          </ul>
          <p className="font-weight-bold">Note : Submit mandatory college attested/ signed/ sealed documents - Marksheet, Hall Ticket, Class Schedule, Transcript, A letter of Enrolment, Registration or Tuition receipt</p>
        </div>
        <div className="overlayBottom">
          <div className="overlayBox">
            <div className="buttons">
              <button onClick={() => { setPhotoScreen(photoScreen + 1); }}>Continue</button>
            </div>
          </div>
        </div>
      </div> }
    </div> }

    { !successMessage && !errorMessage && !permissionDenied && photoScreen === 2 && <div>
      { imageObject == null && <div className="">
        <div className="container">
          <p className="font-size-18 font-weight-bold mb-0">Upload Documents</p>
          <p className="dec font-size-12 mt-0">Please upload your college documents to complete the verification</p>
          {
            menuLists.map(({ href, doc, title, description }, i) => (
              <Card key={i}>
                <div>
                  <span className="font-size-12">{description}</span>
                  <p className="font-weight-bold font-size-12 m-0">{title}</p>
                  {doc === documentCount && <span className="capture" onClick={() => { setPhotoScreen(photoScreen + 1); }}>Capture</span> }
                </div>
              </Card>
            ))
          }
        </div>
      </div> }
    </div> }

    { !successMessage && !errorMessage && !permissionDenied && photoScreen === 3 && <div>
      { !imageObject && <div className="camera">
        <div className="overlayTop">
          <div className="overlayBox">
            <div>Take a photo of the Document {documentCount} {documentCount === 1 && 'FRONT'}</div>
          </div>
        </div>
        <Webcam
          height = { size.height }
          width = { size.width }
          screenshotFormat = "image/jpeg"
          videoConstraints = { videoConstraints }
          ref = { webcamRef }
          onUserMedia = { (data) => onMediaHandler(data) }
          onUserMediaError = { (error) => onMediaErrorHandler(error) }
          //ref={camera => window.camera = camera}
        />
        <div className="focusImage">
          <img
            src={focusImage}
            alt="turn your ID card"
            className="img-fluid"
          />
        </div>
        <div className="overlayBottom">
          <div className="overlayBox">
            <div className="text-center">Place your Document {documentCount} on a flat surface and ensure image is clear</div>
            <div className="buttons">
              <button className="capture" onClick={capture} disabled={isButtonDisabled}>Capture</button>
            </div>
          </div>
        </div>
      </div> }

      {imageObject && <div className="camera">
        <div className="overlayTop">
          <div className="overlayBox">
            <div>Take a photo of the Document {documentCount} {documentCount === 1 && 'FRONT'}</div>
          </div>
        </div>
        <img src={imageObject} alt="" />
        <div className="overlayBottom">
          <div className="overlayBox">
            <div className="text-center">Is this image of your document clear?</div>
            <div className="buttons">
              <button onClick={reTakeHandler}>Retake</button>
              <button onClick={looksGoodHandler}>Looks Good</button>
            </div>
          </div>
        </div>
      </div> }
    </div> }
  </>
  );
}