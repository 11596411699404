import React from 'react';

import './Footer.css';

import TokenService from "../../services/token.service";
import AuthService from "../../services/auth.service";

export default function Footer() {

  const backToApp = TokenService.getPanStatus() && TokenService.getVisualAuthStatus() && TokenService.getStudentAuthStatus() && TokenService.getCollegeDocAuthStatus();
  console.log(backToApp)

  function backToAppHandler() {
    AuthService.logout()
  }

  return (
    <div className="footer">
      <div className="buttons">
        <button onClick={backToAppHandler} disabled={!backToApp?'disabled':''}>Continue to Authenticate</button>
      </div>
    </div>
  )
}
//<button onClick={backToAppHandler} disabled={!backToApp?'disabled':''}>Continue to Authenticate</button>