import React, { useCallback, useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import adapter from "webrtc-adapter";

import './StudentAuthentication.css';
import TokenService from "../services/token.service";
import AuthService from "../services/auth.service";
import history from '../history';

import Success from '../components/UI/Success';
import Failed from '../components/UI/Failed';
import Loader from '../components/UI/Loader';
import FailedCamera from '../components/UI/FailedCamera';
import TimeOutBlocker from '../components/UI/TimeOutBlocker';

import turnUserID from './../images/turn-user-id.svg';
import focusImage from './../images/focus-image.svg';
import speakLouder from './../images/speak-louder.svg';

//import useWindowSize from '../components/useWindowSize';
import useEffectOnce from "../utilities/useEffectOnce"

export default function StudentAuthentication() {
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [videoUrl, setVideoUrl] = useState();
    const [videoScreen, setVideoScreen] = useState(0);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [timeOutBlocker, setTimeOutBlocker] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [timer, setTimer] = useState('00');
    const [mediaStream, setMediaStream] = useState(null);
    const [facingMode, setFacingMode] = useState('environment'); // Initialize with 'user/environment' (front/Back camera)
    const countDownTimer = useRef(null);
    //let downloadTimer;

    const userId = TokenService.getUserId();

    //useWindowSize
    const size = TokenService.getWindowSize();
//    const videoConstraints = {
//      facingMode: facingMode,
//      aspectRatio: size.ratio,
//      height:size.height,
//      width:size.width,
//    }
//    const videoConstraints = {
//        audio: true,
//        muted: true,
//        video: {
//            height: {exact:size.width},
//            width: {exact:size.height},
//            facingMode: facingMode,
//            aspectRatio: size.ratio,
//        }
//    }
    const videoConstraints = {
      audio: true,
      muted: true,
      video: {
        width: 320, height: 240, facingMode: facingMode,
      }
    };

    console.log(videoConstraints)
    //useWindowSize

    const handleDataAvailable = useCallback(
      ({ data }) => {
        if (data.size > 0) {
          setRecordedChunks((prev) => prev.concat(data));
        }
      },
      [setRecordedChunks]
    );

    //const handleShowVideo = useEffect(() => {
    useEffect(() => {
        if (recordedChunks.length) {
          const blob = new Blob(recordedChunks, {
            type: "video/webm",
          });
          const audioURL = window.URL.createObjectURL(blob);
          setVideoUrl(audioURL);
        }
      }, [recordedChunks]
    );

    useEffectOnce(() => {handleOpenCamera()})

    const handleOpenCamera = async () => {
    //https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
      await navigator.mediaDevices
      //.getUserMedia({ video: videoConstraints, audio: true, muted: true, })
      .getUserMedia(videoConstraints)
      .then((stream) => {
        /* use the stream */
        window.localStream = stream;
        videoRef.current.srcObject = stream;
        mediaRecorderRef.current = new MediaRecorder(videoRef.current.srcObject, { mimeType: "video/webm", });
        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable
        );
        //mediaRecorderRef.current.start();
        //setCapturing(true);
        //setMediaStream(stream);
        onMediaHandler()
      })
      .catch((error) => {
        if (error.name === "OverconstrainedError") {
          console.error(
            `The resolution ${videoConstraints.video.width.exact}x${videoConstraints.video.height.exact} px is not supported by your device.`,
          );
        } else if (error.name === "NotAllowedError") {
          console.error(
            "You need to grant this page permission to access your camera and microphone.",
          );
        } else {
          console.error(`getUserMedia error: ${error.name}`, error);
        }
        onMediaErrorHandler(error)
      });
      //.catch((err) => {
      //  /* handle the error */
      //  onMediaErrorHandler(err)
      //});
    };

    const handleStartCaptureClick = useCallback(() => {
      setCapturing(true);
      mediaRecorderRef.current.start();

      /*countdown Timer*/
      var timeleft = 15;
      let downloadTimer = setInterval(function(){
        setTimer((15 - timeleft).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }))
        if(timeleft <= 0){
          //clearInterval(downloadTimer);
          clearInterval(countDownTimer.current);
          setTimeOutBlocker(true)
        }
        timeleft -= 1;
      }, 1000);
      countDownTimer.current = downloadTimer;
      /*countdown Timer*/

  //    setTimeout(() => {
  //      mediaRecorderRef.current.stop();
  //      setCapturing(false);
  //    }, 15000);
      /*https://stackoverflow.com/questions/73702988/how-to-take-webcam-snapshot-after-some-interval-in-stealth-mode-in-react*/

    }, [videoRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

    const handleStopCaptureClick = () => {
      mediaRecorderRef.current.stop();
      //mediaStream.getTracks().forEach((track) => track.stop());
      //clearInterval(downloadTimer);
      clearInterval(countDownTimer.current)
      videoRef.current.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
      setCapturing(false);
    };

    const uploadHandler = useCallback(() => {
      if (recordedChunks.length) {
        const blob = new Blob(recordedChunks, {
          type: "video/webm",
        });

        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async function() {
          var base64data = await reader.result;
          if(blob.size > 0) {
            looksGoodHandler(base64data)
          }
          console.log(base64data);
        }
        //setRecordedChunks([]);
      }
    }, [recordedChunks]);

    async function looksGoodHandler (imageObject) {
      setLoading(true);
      let videoName = videoScreen===2?'StudentID'+videoScreen+'.mp4':'StudentID.mp4';
      let param = 'env=beta&fileName='+videoName+'&cog_id='+userId+'&contentType=video/mp4&from=kycStudentAuth'
      AuthService.imageUpload(param,imageObject).then((response) => {
        setLoading(false);
        //console.log(response);
        //if(videoScreen===0) {
        //  setVideoScreen(1)
        //}
        setSuccessMessage(true)
        if(videoScreen===0) {
          setVideoScreen(1)
          const timeId = setTimeout(() => {
            setSuccessMessage(false)
            clearTimeout(timeId)
            setRecordedChunks([]);
            setTimer('00')
            //handleOpenCamera()
          }, 3000)
        }
        if(videoScreen===2) {
          const timeId = setTimeout(() => {
            setSuccessMessage(false)
            clearTimeout(timeId)
            setRecordedChunks([]);
            setTimer('00');
            history.back();
            navigate('/', { replace: true });
          }, 3000)
        }

      }).catch((err) => {
        setLoading(false);
        //if(videoScreen===0) {
        //  setVideoScreen(1)
        //}
        //if(videoScreen===2) {
          setErrorMessage(true)
          const timeId = setTimeout(() => {
            setErrorMessage(false)
            setRecordedChunks([]);
            setTimer('00')
            clearTimeout(timeId)
            handleOpenCamera()
          }, 3000)
        //}

      })
    }

    //function reTakeHandler() {
    //  setRecordedChunks([]);
    //  setTimer('00');
    //  enableStartButton();
    //}
    function nextVideoScreenStartHandler() {
      setVideoScreen(videoScreen + 1);
      handleOpenCamera()
    }
    function onMediaErrorHandler(error) {
      if(error.name === 'NotAllowedError') {
        setPermissionDenied(true)
      }
    }
    function onMediaHandler(data=null) {
      setPermissionDenied(false)
      enableStartButton()
    }
    function assistHandler(){
      setPermissionDenied(false)
      handleOpenCamera()
    }
    function enableStartButton() {
      setIsButtonDisabled(true)
      const timeIdESB = setTimeout(() => {
        setIsButtonDisabled(false)
        clearTimeout(timeIdESB)
      }, 1000)
    }
    function yesRedoHandler(data){
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.removeEventListener(
        "dataavailable",
        handleDataAvailable
      );
      videoRef.current.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
      setCapturing(false);
      setTimer('00');
      setTimeOutBlocker(false)
      if(data == false) {
        history.back();
        navigate('/', { replace: true });
      } else {
        handleOpenCamera()
      }
    }

    useEffect(() => {
        window.addEventListener('popstate', function(event) {
            // The popstate event is fired each time when the current history entry changes.
            window.localStream.getTracks().forEach((track) => {
                track.stop();
            });
        }, false);
        return () => {
            console.log('******************* UNMOUNTED');
            window.onhashchange = function() {
                navigate('/student-authentication', { replace: true });
            }
        };
    }, []);
    return (
      <>
        { successMessage && <Success /> }
        { errorMessage && <Failed /> }
        { loading && <Loader /> }
        { permissionDenied && <FailedCamera onClick={assistHandler}/> }
        { timeOutBlocker && <TimeOutBlocker onClick={yesRedoHandler} title="Time Out !!" text="Please redo the student authentication." buttonFirst="NO, CANCEL" buttonSecound="YES, REDO"/> }
        { !successMessage && !errorMessage && !permissionDenied && !loading && videoScreen === 0 && size.ratio > 0 && <div>
          { !recordedChunks.length > 0 && <div className="camera">
            <video ref={videoRef} autoPlay playsInline muted/>
            <div className="focusImage">
              <img
                src={focusImage}
                alt="turn your ID card"
                className="img-fluid"
              />
            </div>
            <div className="recorder-time">
              <div className="icon"></div>
              00.{timer}
            </div>
            <div className="overlayTop">
              <div className="overlayBox">
                <div>Take a video of Student ID card</div>
                <span>Align your ID card within the frame</span>
              </div>
            </div>
            <div className="overlayBottom">
              <div className="overlayBox">
                <div className="speakLouder">
                  <img
                    src={speakLouder}
                    alt="speak louder"
                    className="img-fluid"
                  />
                  Please s-l-o-w-l-y READ ALOUD the roll numner on your student ID card.
                </div>
                <div className="buttons">
                  {capturing ? (
                    <button onClick={handleStopCaptureClick}>Stop Capture</button>
                  ) : (
                    <button className="capture" onClick={handleStartCaptureClick} disabled={isButtonDisabled}>Start Capture</button>
                  )}
                </div>
              </div>
            </div>
          </div> }
        </div> }

        { !successMessage && !errorMessage && !permissionDenied && (videoScreen === 0 || videoScreen === 2) && <div>
          {recordedChunks.length > 0 && <div className="camera">
            <video src={videoUrl} controls height = { size.height - 80 } width = "100%"/>
            <div className="overlayBottom">
              <div className="overlayBox">
                <div className="buttons">
                  {/*<button onClick={reTakeHandler}>Retake</button>*/}
                  <button onClick={uploadHandler}>Looks Good</button>
                </div>
              </div>
            </div>
          </div> }
        </div> }

        { !successMessage && !errorMessage && !permissionDenied && videoScreen === 1 && <div>
          { !recordedChunks.length > 0 && <div className="camera">
            <div className="overlayTop">
              <div className="overlayBox">
                <div>Take a video of your Student ID card</div>
              </div>
            </div>
            <div className="turnCardScreen">
              <img
                src={turnUserID}
                alt="turn your ID card"
                className="img-fluid"
              />
            </div>
            <div className="overlayBottom">
              <div className="overlayBox">
                <div className="buttons">
                  <button onClick={nextVideoScreenStartHandler}>Next</button>
                  {/*<button onClick={() => { setVideoScreen(videoScreen + 1); }}>Next</button>*/}
                </div>
              </div>
            </div>
          </div> }
        </div> }

        { !successMessage && !errorMessage && !permissionDenied && !loading && videoScreen === 2 && size.ratio > 0 && <div>
          { !recordedChunks.length > 0 && <div className="camera">
            <video ref={videoRef} autoPlay playsInline muted/>
            <div className="focusImage">
              <img
                src={focusImage}
                alt="turn your ID card"
                className="img-fluid"
              />
            </div>
            <div className="recorder-time">
              <div className="icon"></div>
              00.{timer}
            </div>
            <div className="overlayTop">
              <div className="overlayBox">
                <div>Take a video of Student ID card</div>
                <span>Align your ID card within the frame</span>
              </div>
            </div>
            <div className="overlayBottom">
              <div className="overlayBox">
                <div className="speakLouder">
                  <img
                    src={speakLouder}
                    alt="speak louder"
                    className="img-fluid"
                  />
                  Please s-l-o-w-l-y READ ALOUD the DOB on the ID card if printed.
                </div>
                <div className="buttons">
                  {capturing ? (
                    <button onClick={handleStopCaptureClick}>Stop Capture</button>
                  ) : (
                    <button className="capture" onClick={handleStartCaptureClick} disabled={isButtonDisabled}>Start Capture</button>
                  )}
                </div>
              </div>
            </div>
          </div> }
        </div> }
      </>
    );
  };

// export default VideoCapture;