import api from "./api";

const getVerificationLivelinessOTP = () => {
  return api.get("/verification/verification_liveliness_otp");
};

//const getUserBoard = () => {
//  return api.get("/test/user");
//};
//
//const getModeratorBoard = () => {
//  return api.get("/test/mod");
//};
//
//const getAdminBoard = () => {
//  return api.get("/test/admin");
//};

const UserService = {
  getVerificationLivelinessOTP,
  //getUserBoard,
  //getModeratorBoard,
  //getAdminBoard,
};

export default UserService;
