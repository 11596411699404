import React, { useEffect } from 'react';
import { useMobileOrientation } from 'react-device-detect';

export default function NotAMobileDevice() {
  const { isMobileOnly } = useMobileOrientation()
  useEffect(() => {
      return () => {
          console.log('******************* UNMOUNTED');
          if (isMobileOnly) {
               window.location.pathname = "/";
          }
      };
  }, [isMobileOnly]);
  return (
    <>
      <div className="text-center rotate-your-device">
        <h3>Aw, Snap</h3>
        <p>Oops! Please do open the application in a mobile browser</p>
      </div>
    </>
  );
}