
const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.refreshToken;
};

const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.headers;
};

const getAccessToken = (searchParamId) => {
  const user = JSON.parse(localStorage.getItem("user"));
  //return user?.headers;
  //const tokenString = localStorage.getItem('user');
  //const userToken = JSON.parse(tokenString);
  //const searchParamId = SearchParam();
  if(searchParamId != null && searchParamId !== user?.auth_id && user != null) {
    localStorage.clear();
    return null;
  } else {
    return user?.headers
  }
};

const getUserId = () => {
  return localStorage.getItem('userInfo');
};

//const getTokenId = () => {
//  return localStorage.getItem('idToken');
//};

const updateLocalAccessToken = (token) => {
  let user = JSON.parse(localStorage.getItem("user"));
  user.headers = token;
  localStorage.setItem("user", JSON.stringify(user));
};

const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const setUser = (user) => {
  console.log(JSON.stringify(user));
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem('idToken', user.headers);
  localStorage.setItem('userInfo', user.student_cog_id);
  localStorage.setItem('authId', user.auth_id);
  localStorage.setItem('userStatus', user.user_status);
  localStorage.setItem('panStatus', user.identityStatus);
  localStorage.setItem('visualAuthStatus', user.visual_authentication);
  localStorage.setItem('studentAuthStatus', user.student_authentication);
  localStorage.setItem('collegeDocAuthStatus', user.college_documents);
  localStorage.setItem('token', JSON.stringify(user));
};

const getUserStatus = () => {
  return localStorage.getItem("userStatus");
};

const setPanStatus = (param) => {
  console.log(JSON.stringify(param));
  localStorage.setItem("panStatus", param);
};
const getPanStatus = () => {
  //return JSON.parse(localStorage.getItem("panStatus"));
  return getUserStatus() === 'registered'? JSON.parse(localStorage.getItem("panStatus")) === false ? null : JSON.parse(localStorage.getItem("panStatus")) : JSON.parse(localStorage.getItem("panStatus"));
};
const setVisualAuthStatus = (param) => {
  console.log(JSON.stringify(param));
  localStorage.setItem("visualAuthStatus", param);
};
const getVisualAuthStatus = () => {
  return getUserStatus() === 'registered'? JSON.parse(localStorage.getItem("visualAuthStatus")) === false ? null : JSON.parse(localStorage.getItem("visualAuthStatus")) : JSON.parse(localStorage.getItem("visualAuthStatus"));
  //return JSON.parse(localStorage.getItem("visualAuthStatus")) === false ? null : JSON.parse(localStorage.getItem("visualAuthStatus"));
};
const setStudentAuthStatus = (param) => {
  console.log(JSON.stringify(param));
  localStorage.setItem("studentAuthStatus", param);
};
const getStudentAuthStatus = () => {
  return getUserStatus() === 'registered' ? JSON.parse(localStorage.getItem("studentAuthStatus")) === false ? null : JSON.parse(localStorage.getItem("studentAuthStatus")) : JSON.parse(localStorage.getItem("studentAuthStatus"));
  //return JSON.parse(localStorage.getItem("studentAuthStatus")) === false ? null : JSON.parse(localStorage.getItem("studentAuthStatus"));
};
const setCollegeDocAuthStatus = (param) => {
  console.log(JSON.stringify(param));
  localStorage.setItem("collegeDocAuthStatus", param);
};
const getCollegeDocAuthStatus = () => {
  return getUserStatus() === 'registered' ? JSON.parse(localStorage.getItem("collegeDocAuthStatus")) === false ? null : JSON.parse(localStorage.getItem("collegeDocAuthStatus")) : JSON.parse(localStorage.getItem("collegeDocAuthStatus"));
  //return JSON.parse(localStorage.getItem("collegeDocAuthStatus")) === false ? null : JSON.parse(localStorage.getItem("collegeDocAuthStatus"));
};

const setWindowSize = (param) => {
  console.log(JSON.stringify(param));
  localStorage.setItem("size", JSON.stringify(param));
};

const getWindowSize = () => {
  return JSON.parse(localStorage.getItem("size"));
};

const removeUser = () => {
  localStorage.removeItem("user");
  localStorage.clear();
  window.location = process.env.REACT_APP_REDIRECTION_URL;
  //close();
  //window.open('','_self','');
  //window.open('','_parent','');
  window.close();
  //open(location, '_self').close();
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  getAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  getUserId,
  setPanStatus,
  getPanStatus,
  setVisualAuthStatus,
  getVisualAuthStatus,
  setStudentAuthStatus,
  getStudentAuthStatus,
  setCollegeDocAuthStatus,
  getCollegeDocAuthStatus,
  setWindowSize,
  getWindowSize
};

export default TokenService;
