import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from './Card';

import './TimeOutBlocker.css'

//function Card(props){
export default function TimeOutBlocker(props) {
  const navigate = useNavigate();
  //const Loader = (props) => {
  //const classes = 'card ' + props.className;
  //function noCancelHandler() {
  //  navigate('/', { replace: true });
  //}

  return (
    <div className="overlay">
        <div className="success-container">
          <Card>
            <div className="warning-msg"><h1 className="mt-0">{props.title}</h1>{props.text && <p>{props.text}</p>}</div>
            <div className="buttons">
              {props.buttonFirst && <button type="button" className="btn btn-success" onClick={() => props.onClick(false)}>{ props.buttonFirst }</button> }
              {props.buttonSecound && <button type="button" className="btn btn-success" onClick={() => props.onClick(true)}>{ props.buttonSecound }</button> }
            </div>
          </Card>
        </div>
    </div>
  );
}
//export default Loader;