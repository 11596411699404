import { useState, useEffect } from 'react';

export default function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    ratio: undefined,
  });
//videoConstraints.width = document.documentElement.clientWidth;
//videoConstraints.height = document.documentElement.clientHeight;
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      const isLandscape = window.innerHeight <= window.innerWidth;
      const ratio = isLandscape ? (window.innerWidth-10) / (window.innerHeight-10) : (window.innerHeight-10) / (window.innerWidth-10);
      setWindowSize({
        width: (window.innerWidth-10),
        height: (window.innerHeight-10),
        ratio: ratio
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

//https://www.google.com/search?q=react-webcam+full+size+video+in+mobile&rlz=1C1CHBF_enIN840IN840&oq=react-webcam+full+size+video+in+mobile&aqs=chrome..69i57.16353j0j1&sourceid=chrome&ie=UTF-8
//https://stackoverflow.com/questions/55920961/react-webcam-npm-package-video-sizing
//https://usehooks.com/useWindowSize/
//https://carmodity-camera.netlify.app/