import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Webcam from "react-webcam";
import adapter from "webrtc-adapter";

import useWindowSize from '../components/useWindowSize';
import TokenService from "../services/token.service";
import AuthService from "../services/auth.service";
import history from '../history';

import './Pan.css';
import Success from '../components/UI/Success';
import Failed from '../components/UI/Failed';
import Loader from '../components/UI/Loader';
import FailedCamera from '../components/UI/FailedCamera';

import focusImage from './../images/focus-image.svg';

export default function Pan() {
  const webcamRef = useRef(null);
  const [imageObject, setImageObject] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  //const token = getToken();
  //console.log(token)
  const userId = TokenService.getUserId();

  //useWindowSize
  const size = useWindowSize();
  //const isLandscape = size.height <= size.width;
  //const ratio = isLandscape ? size.width / size.height : size.height / size.width;
  const videoConstraints = {
    facingMode: 'environment',
    aspectRatio: size.ratio
  }

  const capture = React.useCallback(
    () => {
      setImageObject(webcamRef.current.getScreenshot());
      //setImageObject(window.camera.getScreenshot());
    },
    [webcamRef, setImageObject]
  );

  function reTakeHandler() {
    setImageObject(null)
    enableStartButton()
  }

  async function looksGoodHandler () {
    setLoading(true);
    let param = 'env=beta&fileName=PANcardFront.jpg&cog_id='+userId+'&contentType=image/jpeg&from=kycPan'
    AuthService.imageUpload(param,imageObject).then((data) => {
      AuthService.panUpdate(data).then(() => {
        setLoading(false);
        setSuccessMessage(true)
        setImageObject(null)
        const timeId = setTimeout(() => {
          setSuccessMessage(false)
          clearTimeout(timeId)
          history.back();
          navigate('/', { replace: true });
        }, 3000)
      }).catch((err) => {
        console.log(err);
        setLoading(false);
        setImageObject(null)
        setErrorMessage(true)
        const timeId = setTimeout(() => {
          setErrorMessage(false)
          clearTimeout(timeId)
        }, 3000)
      })
    }).catch((err) => {
      console.log(err);
      setLoading(false);
      setImageObject(null)
      setErrorMessage(true)
      const timeId = setTimeout(() => {
        setErrorMessage(false)
        clearTimeout(timeId)
      }, 3000)
    })
  }
  //    { successMessage && <div className={successMessage?'container success-bg':''}><Card><div className="success-msg"><span className="success-tick">&#10003;</span><h1>Success</h1><p>We received your upload request.<br/>we will be in touch shortly!</p></div></Card></div>}
  //    { errorMessage && <div className={errorMessage?'container error-bg':''}><Card><div className="error-msg"><span className="error-cross">&#215;</span><h1>Failed</h1><p>We are not received your upload request.<br/>Please try aging!</p></div></Card></div>}

  function onMediaErrorHandler(error) {
    if(error.name === 'NotAllowedError') {
      //alert('error: '+error.name);
      setPermissionDenied(true)
    }
  }
  function onMediaHandler(data) {
    setPermissionDenied(false)
    enableStartButton()
  }
  function assistHandler(){
    setPermissionDenied(false)
  }
  function enableStartButton() {
    setIsButtonDisabled(true)
    const timeIdESB = setTimeout(() => {
      setIsButtonDisabled(false)
      clearTimeout(timeIdESB)
    }, 1000)
  }
  useEffect(() => {
      //window.scrollTo(0, 1);
      return () => {
          console.log('******************* UNMOUNTED');
          window.onhashchange = function() {
              navigate('/pan', { replace: true });
          }
      };
  }, []);
  return (
  <>
    { successMessage && <Success /> }
    { errorMessage && <Failed /> }
    { loading && <Loader /> }
    { permissionDenied && <FailedCamera onClick={assistHandler}/> }
    { !successMessage && !errorMessage && !permissionDenied && <div>
      { !imageObject && <div className="camera card-shape">
        <div className="overlayTop">
          <div className="overlayBox">
            <div>Take a photo of your PAN card FRONT</div>
          </div>
        </div>
        <Webcam
          height = { size.height }
          width = { size.width }
          screenshotFormat = "image/jpeg"
          videoConstraints = { videoConstraints }
          ref = { webcamRef }
          onUserMedia = { (data) => onMediaHandler(data) }
          onUserMediaError = { (error) => onMediaErrorHandler(error) }
          //ref={camera => window.camera = camera}
        />
        <div className="focusImage">
          <img
            src={focusImage}
            alt="turn your ID card"
            className="img-fluid"
          />
        </div>
        <div className="overlayBottom">
          <div className="overlayBox">
            <div className="text-center">Place your PAN card on a flat surface and within the borders of the frame to ensure that the image is clear with no glare of light.</div>
            <div className="buttons">
              <button className="capture" onClick={capture} disabled={isButtonDisabled}>Capture</button>
            </div>
          </div>
        </div>
      </div> }
    </div> }

    { !successMessage && !errorMessage && !permissionDenied && <div>
      {imageObject && <div className="camera">
        <img src={imageObject} alt="" />
        <div className="overlayTop">
          <div className="overlayBox">
            <div>Take a photo of your PAN card FRONT</div>
          </div>
        </div>
        <div className="overlayBottom">
          <div className="overlayBox">
            <div className="text-center">Is this image of your Permanent Account Number(PAN) card FRONT clear?</div>
            <div className="buttons">
              <button onClick={reTakeHandler}>Retake</button>
              <button onClick={looksGoodHandler}>Looks Good</button>
            </div>
          </div>
        </div>
      </div> }
    </div> }
  </>
  );
}
//<button className="retake" onClick={reTakeHandler}>Retake</button>
//<button className="looksgood" onClick={looksGoodHandler}>Looks Good</button>