import React from 'react';
import loader from './../../loader.gif';
import './Loader.css'

//function Card(props){
export default function Loader() {
//const Loader = (props) => {
  //const classes = 'card ' + props.className;
  return (
    <div className="overlay">
      <div className="loading">
        <img
          src={loader}
          alt="Loading..."
          className="img-fluid"
        />
      </div>
    </div>
  );
}
//export default Loader;