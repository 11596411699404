import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import adapter from "webrtc-adapter";

import useEffectOnce from "../utilities/useEffectOnce"
import TokenService from "../services/token.service";
import AuthService from "../services/auth.service";
import history from '../history';

import './CollegeDocumentAuthentication.css';
import Card from '../components/UI/Card';
import Success from '../components/UI/Success';
import Failed from '../components/UI/Failed';
import Loader from '../components/UI/Loader';
import FailedCamera from '../components/UI/FailedCamera';

import focusImage from './../images/focus-image.svg';

export default function CollegeDocumentAuthentication() {
  const videoRef = useRef(null);
  const [imageObject, setImageObject] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [photoScreen, setPhotoScreen] = useState(1);
  const [documentCount, setDocumentCount] = useState(1);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [facingMode, setFacingMode] = useState('environment'); // Initialize with 'user/environment' (front/Back camera)

  navigator.getUserMedia = (
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia
  );

  const userId = TokenService.getUserId();

  //useWindowSize
  const size = TokenService.getWindowSize();
  //const videoConstraints = {
  //  facingMode: facingMode,
  //  aspectRatio: size.ratio,
  //  height:size.height,
  //  width:size.width,
  //}
//  const videoConstraints = {
//      audio: true,
//      muted: true,
//      video: {
//          height: size.width,
//          width: size.height,
//          facingMode: facingMode,
//          aspectRatio: size.ratio,
//      },
//      screenshotFormat : "image/jpeg"
//  }
  const videoConstraints = {
    audio: true,
    muted: true,
    video: {
      width: 320, height: 240, facingMode: facingMode,
    }
  };
  console.log(videoConstraints)
  //useWindowSize

  //useEffectOnce(() => {handleOpenCamera()})
  const startCameraCaptureHandler = () => {
      setPhotoScreen(photoScreen + 1);
      handleOpenCamera()
  }

  const handleOpenCamera = async () => {
      console.log(videoConstraints)
      //https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
      await navigator.mediaDevices
      //.getUserMedia({ video: videoConstraints, audio: true, muted: true, })
      .getUserMedia(videoConstraints)
      .then((stream) => {
          /* use the stream */
          window.localStream = stream;
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          //setMediaStream(stream);
          onMediaHandler()
      })
      .catch((err) => {
          console.log(err)
          /* handle the error */
          onMediaErrorHandler(err)
      });
  };
  function setInRange(value, range) {
    if(!range) return NaN;
    let x = Math.min(range.max, Math.max(range.min, value));
    x = Math.round(x / range.step) * range.step; // take `step` into account
    return x;
  }
  const capture = React.useCallback(
      async () => {
          const track = videoRef.current.srcObject.getVideoTracks()[0]
          let imageCapture = new ImageCapture(track);
          const { imageWidth, imageHeight } = await imageCapture.getPhotoCapabilities();
          const width = setInRange(size.width, imageWidth);
          const height = setInRange(size.height, imageHeight);
          const photoSettings = (width && height) ? {
            imageWidth: width,
            imageHeight: height
          } : null;

          imageCapture.takePhoto(photoSettings)
              .then(blob => {
                  videoRef.current.srcObject.getTracks().forEach((track) => {
                    track.stop();
                  });
                  var reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = async function() {
                      var base64data = await reader.result;
                      if(blob.size > 0) {
                          setImageObject(base64data)
                      }
                      //console.log(base64data);
                  }
              })
              .catch(error => onMediaErrorHandler(error));
      },
      [videoRef, setImageObject]
  );

  function reTakeHandler() {
      setImageObject(null)
      handleOpenCamera()
      enableStartButton()
  }

  async function looksGoodHandler () {
      setLoading(true);
      let photoName = 'Document_'+documentCount+'.jpg';
      let param = 'env=beta&fileName='+photoName+'&cog_id='+userId+'&contentType=image/jpeg&from=kycCollegeDocAuth&name=Document '+documentCount
      AuthService.imageUpload(param,imageObject).then((response) => {
          setLoading(false);
          setSuccessMessage(true)
          setImageObject(null)

          const timeId = setTimeout(() => {
              setSuccessMessage(false)
              clearTimeout(timeId)
              if(documentCount === 3) {
                  //history.back();
                  navigate('/', { replace: true });
              } else {
                  handleOpenCamera()
              }
              setDocumentCount(documentCount + 1);
          }, 3000)
      }).catch((err) => {
          console.log(err);
          setLoading(false);
          setImageObject(null)
          setErrorMessage(true)
          const timeId = setTimeout(() => {
              setErrorMessage(false)
              clearTimeout(timeId)
              handleOpenCamera()
          }, 3000)
      })
  }
  function onMediaErrorHandler(error) {
      if(error.name === 'NotAllowedError') {
          setPermissionDenied(true)
      }
  }
  function onMediaHandler(data) {
      setPermissionDenied(false)
      enableStartButton()
  }
  function assistHandler(){
      setPermissionDenied(false)
      handleOpenCamera()
  }
  function enableStartButton() {
      setIsButtonDisabled(true)
      const timeIdESB = setTimeout(() => {
          setIsButtonDisabled(false)
          clearTimeout(timeIdESB)
      }, 1000)
  }
  useEffect(() => {
      window.addEventListener('popstate', function(event) {
          // The popstate event is fired each time when the current history entry changes.
          window.localStream.getTracks().forEach((track) => {
              track.stop();
          });
      }, false);
      return () => {
          console.log('******************* UNMOUNTED');
          window.onhashchange = function() {
              navigate('/college-document-authentication', { replace: true });
          }
      };
  }, []);

  const menuLists = [
    {
      href: 'pan',
      doc: 1,
      title: 'Document 1',
      description: 'College issued Document',
    },
    {
      href: 'visualauth',
      doc: 2,
      title: 'Document 2',
      description: 'College issued Document',
    },
    {
      href: 'studentauth',
      doc: 3,
      title: 'Document 3',
      description: 'College issued Document',
    }
  ];

  return (
  <>
    { successMessage && <Success /> }
    { errorMessage && <Failed /> }
    { loading && <Loader /> }
    { permissionDenied && <FailedCamera onClick={assistHandler}/> }
    { !successMessage && !errorMessage && !permissionDenied && photoScreen === 1 && <div>
      { imageObject == null && <div className="container">
        <div className="font-size-14">
          <p className="font-size-18 font-weight-bold mb-0">Upload Documents</p>
          <p className="dec font-size-12 mt-0">Upload any of three of the following documents</p>
          <p className="font-weight-bold">You may capture and upload up to 3 College- issued documents to prove you are currently enrolled in a college. Your document must display:</p>
          <ul>
            <li>Your first name(Rahul)</li>
            <li>Your last name(Sharma)</li>
            <li>The full name of college/university you are currently attending(Nirma University Ahmedabad, Gujarat)</li>
            <li>Must show an issue date within last 3 months</li>
          </ul>
          <p className="font-weight-bold">Note : Submit mandatory college attested/ signed/ sealed documents - Marksheet, Hall Ticket, Class Schedule, Transcript, A letter of Enrolment, Registration or Tuition receipt</p>
        </div>
        <div className="overlayBottom">
          <div className="overlayBox">
            <div className="buttons">
              <button onClick={() => { setPhotoScreen(photoScreen + 1); }}>Continue</button>
            </div>
          </div>
        </div>
      </div> }
    </div> }

    { !successMessage && !errorMessage && !permissionDenied && photoScreen === 2 && <div>
      { imageObject == null && <div className="">
        <div className="container">
          <p className="font-size-18 font-weight-bold mb-0">Upload Documents</p>
          <p className="dec font-size-12 mt-0">Please upload your college documents to complete the verification</p>
          {
            menuLists.map(({ href, doc, title, description }, i) => (
              <Card key={i}>
                <div>
                  <span className="font-size-12">{description}</span>
                  <p className="font-weight-bold font-size-12 m-0">{title}</p>
                  {/*doc === documentCount && <span className="capture" onClick={() => { setPhotoScreen(photoScreen + 1); }}>Capture</span> */}
                  {doc === documentCount && <span className="capture"  onClick={startCameraCaptureHandler}>Capture</span> }
                </div>
              </Card>
            ))
          }
        </div>
      </div> }
    </div> }

    { !successMessage && !errorMessage && !permissionDenied && photoScreen === 3 && <div>
      { !imageObject && <div className="camera">
        <div className="overlayTop">
          <div className="overlayBox">
            <div>Take a photo of the Document {documentCount} {documentCount === 1 && 'FRONT'}</div>
          </div>
        </div>
        <video ref={videoRef} autoPlay playsInline muted/>
        <div className="focusImage">
          <img
            src={focusImage}
            alt="turn your ID card"
            className="img-fluid"
          />
        </div>
        <div className="overlayBottom">
          <div className="overlayBox">
            <div className="text-center">Place your Document {documentCount} on a flat surface and ensure image is clear</div>
            <div className="buttons">
              <button className="capture" onClick={capture} disabled={isButtonDisabled}>Capture</button>
            </div>
          </div>
        </div>
      </div> }

      {imageObject && <div className="camera">
        <div className="overlayTop">
          <div className="overlayBox">
            <div>Take a photo of the Document {documentCount} {documentCount === 1 && 'FRONT'}</div>
          </div>
        </div>
        <img src={imageObject} alt="" />
        <div className="overlayBottom">
          <div className="overlayBox">
            <div className="text-center">Is this image of your document clear?</div>
            <div className="buttons">
              <button onClick={reTakeHandler}>Retake</button>
              <button onClick={looksGoodHandler}>Looks Good</button>
            </div>
          </div>
        </div>
      </div> }
    </div> }
  </>
  );
}