import React, { useState, useEffect } from 'react'
//import PropTypes from 'prop-types';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './Login.css';
import AuthService from "../services/auth.service";
import logo from '../stucred-logo.png';

import history from '../history';

import Loader from '../components/UI/Loader'
export default function Login() {
//export default function Login(props) {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false);

  const logSearchParams = () => {
    return searchParams.get("id")
  };

  useEffect(() => {
    const param = logSearchParams()
    if(param != null) {
      setLoading(true);
      AuthService.login(param).then(
        () => {
          //localStorage.clear();
          //props.history.push("/");
          navigate('/', { replace: true });
          //window.location.reload();
          setLoading(false);
        },
        (err) => {
          setErrorMessage(err.response.data.error)
          setLoading(false);
          // On componentDidMount set the timer
          //useEffect(() => {
            history.push('/');
            navigate('/', { replace: true });
            const timeId = setTimeout(() => {
              // After 3 seconds set the show value to false
              setErrorMessage(null)
              clearTimeout(timeId)
            }, 3000)
          console.log(err.response.data.error);
        }
      );
    }
  }, [logSearchParams]);

  function backToAppHandler() {
    window.location='https://stucred.page.link/Beta_UVScreen'
  }
  function backToAppHandlerassign() {
    window.location.assign('https://stucred.page.link/Beta_UVScreen')
  }
  function backToAppHandlerreplace() {
    window.location.replace('https://stucred.page.link/Beta_UVScreen')
  }
  function backToAppHandleropen() {
    window.open('https://stucred.page.link/Beta_UVScreen','_self','');
  }
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="StuCred® | Real-Time Student Credit | Logo" />
        <div className="App-text">
          Welcome To StuCred KYC
        </div>
        <div>version 3.00</div>
        <button onClick={backToAppHandleropen}>Continue to Authenticate open</button><br/>
        <button onClick={backToAppHandlerreplace}>Continue to Authenticate replace</button><br/>
        <button onClick={backToAppHandlerassign}>Continue to Authenticate assign</button><br/>
        <button onClick={backToAppHandler}>Continue to Authenticate</button><br/>
        <button onClick="window.location='https://stucred.page.link/Beta_UVScreen'">window.location</button><br/>
        <button onClick="window.location.href='https://stucred.page.link/Beta_UVScreen'">location.href</button><br/>
        <a onClick="window.location='https://stucred.page.link/Beta_UVScreen'">window.location</a><br/>
        <a onClick="window.location.href='https://stucred.page.link/Beta_UVScreen'">location.href</a><br/>
        <a href="window.location='https://stucred.page.link/Beta_UVScreen'">href window.location</a><br/>
        <a href="window.location.href='https://stucred.page.link/Beta_UVScreen'">href location.href</a><br/>
        <a href="https://stucred.page.link/Beta_UVScreen">href</a>
        { errorMessage && <div>{errorMessage}</div>}
        {loading && <Loader /> }
      </header>
    </div>
  );
}

//Login.propTypes = {
//  setToken: PropTypes.func.isRequired
//}